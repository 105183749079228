/**
 * Define these bootstrap-specific variables before importing bootstrap styles.
 * These variables will override bootstrap's default values.
 */
$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.17rem;
$headings-font-weight: bold;
$headings-line-height: 1.5;

/**
 * Only import the necessary parts of bootstrap to reduce the total bundle size.
 */
@import 'bootstrap/scss/bootstrap-utilities';
@import 'bootstrap/scss/_reboot';
@import 'bootstrap/scss/_grid';
@import 'bootstrap/scss/_containers';

// @import 'bootstrap/scss/_accordion';
// @import 'bootstrap/scss/_alert';
// @import 'bootstrap/scss/_badge';
// @import 'bootstrap/scss/_breadcrumb';
// @import 'bootstrap/scss/_button-group';
// @import 'bootstrap/scss/_buttons';
// @import 'bootstrap/scss/_card';
// @import 'bootstrap/scss/_carousel';
// @import 'bootstrap/scss/_close';
// @import 'bootstrap/scss/_dropdown';
// @import 'bootstrap/scss/_forms';
// @import 'bootstrap/scss/_functions';
// @import 'bootstrap/scss/_helpers';
// @import 'bootstrap/scss/_images';
// @import 'bootstrap/scss/_list-group';
// @import 'bootstrap/scss/_maps';
// @import 'bootstrap/scss/_mixins';
// @import 'bootstrap/scss/_modal';
// @import 'bootstrap/scss/_nav';
// @import 'bootstrap/scss/_navbar';
// @import 'bootstrap/scss/_offcanvas';
// @import 'bootstrap/scss/_pagination';
// @import 'bootstrap/scss/_placeholders';
// @import 'bootstrap/scss/_popover';
// @import 'bootstrap/scss/_progress';
// @import 'bootstrap/scss/_root';
// @import 'bootstrap/scss/_spinners';
// @import 'bootstrap/scss/_tables';
// @import 'bootstrap/scss/_toasts';
// @import 'bootstrap/scss/_tooltip';
// @import 'bootstrap/scss/_transitions';
// @import 'bootstrap/scss/_type';
// @import 'bootstrap/scss/_utilities';
// @import 'bootstrap/scss/_variables-dark';
// @import 'bootstrap/scss/_variables';
// @import 'bootstrap/scss/bootstrap-grid';
// @import 'bootstrap/scss/bootstrap-reboot';
