@import './scss/bbraun-theme.scss';
@import './scss/bootstrap.scss';

.color-green {
  color: $bbraun-green !important;
}

.color-purple {
  color: $bbraun-purple !important;
}

.color-grey {
  color: $bbraun-grey !important;
}

.color-dark-red {
  color: $bbraun-dark-red !important;
}

.bg-green {
  background: $bbraun-green !important;
}

.bg-light-grey {
  background: $bbraun-light-grey !important;
}

.scrollable-table {
  overflow: auto;
}

* {
  outline: none !important;
}

.container {
  max-width: unset !important;
  padding-right: 10% !important;
  padding-left: 10% !important;
  @media screen and (max-width: 1024px) {
    padding-right: 5% !important;
    padding-left: 5% !important;
  }
}

table[mat-table] {
  tr {
    animation: 250ms ease-in fade-in;
  }

  tr > td:last-child {
    padding-right: 12px !important;
  }

  td:not(.mat-column-details),
  th:not(.mat-column-details) {
    min-width: 110px;
    padding-left: 12px !important;
  }
}

.mat-paginator-container {
  padding: 0 !important;
}

// Mat-tab menu Styleguide styles
.mat-tab-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-tab-label {
  font-size: 18px;
  font-weight: bold;
  color: #a7a7a7;
  opacity: 1 !important;
}

.mat-tab-label-active,
.mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus {
  background-color: transparent;
  color: $bbraun-green;
  opacity: 1 !important;
}

.mat-tab-header-pagination {
  box-shadow: none !important;
}

.mat-tab-header {
  border-bottom: none;
}

.cursor-drag {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.mat-dialog-container {
  border-radius: 0 !important;
}

body {
  margin: 0;
}

.clickable {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 0 0 0 0 !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.mat-form-field-ripple {
  background-color: $bbraun-green;
}

.view-margin-bottom {
  margin-bottom: 100px !important;
}

.property-edit-menu {
  min-width: 500px !important;
  @include media-breakpoint-down(xl) {
    min-width: 420px !important;
  }
  @include media-breakpoint-down(lg) {
    min-width: 330px !important;
  }
  @include media-breakpoint-down(md) {
    min-width: 265px !important;
  }
  & .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.property-edit-menu-full-width {
    min-width: 85vw !important;
    @include media-breakpoint-up(md) {
      min-width: 88vw !important;
    }
    @include media-breakpoint-up(lg) {
      min-width: 78vw !important;
    }
  }

  &.property-edit-menu-mid-width {
    max-width: unset !important;
    @include media-breakpoint-up(lg) {
      min-width: 830px !important;
    }
  }
}

a.unstyled,
a.unstyled:hover {
  color: inherit;
  text-decoration: none;
}

.mat-input-element:disabled {
  color: inherit;
}

.mat-icon {
  transition: opacity 100ms ease;
}

.cdk-overlay-container {
  z-index: 1020;
}

mat-label mat-icon {
  // To enable tooltip display on icons inside of mat-form-field labels
  pointer-events: all;
}

.label-help-icon-menu {
  min-width: 22rem !important;
  max-width: 90vw !important;

  @include media-breakpoint-up(md) {
    max-width: 50vw !important;
  }

  & .mat-menu-content {
    padding: 0.4rem 0.8rem 0.6rem !important;

    & > strong,
    > a {
      color: #585858;
      text-transform: uppercase;
      font-size: 73%;
    }

    & > a {
      font-weight: bold;
    }

    & p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.label-help-icon-menu-backdrop {
  background: rgba(0, 0, 0, 0.05);
}

.pending-snackbar {
  background-color: $bbraun-orange;
  margin-bottom: 68px !important;
  @media (min-width: 490px) {
    max-width: 70vw !important;
  }

  // The following colors are lighter variants of $bbraun-orange (higher lightness)
  .mat-progress-bar-fill:after {
    background-color: hsl(23, 78%, 95%) !important;
  }

  .mat-progress-bar-background {
    fill: hsl(23, 78%, 75%) !important;
  }

  .mat-progress-bar-buffer {
    background-color: hsl(23, 78%, 75%) !important;
  }
}

.success-snackbar {
  margin-bottom: 68px !important;
  background-color: $bbraun-green;

  @media (min-width: 490px) {
    max-width: 70vw !important;
  }
}

.error-snackbar {
  margin-bottom: 68px !important;
  min-width: min(344px, 90vw) !important;
  @media (min-width: 490px) {
    max-width: 70vw !important;
  }

  & div {
    width: 100%;
  }
}

mat-form-field {
  &.prepare-border {
    & .mat-form-field-flex {
      transition: all 150ms ease;

      $border-width: 3px;
      border-left: $border-width solid transparent !important;
      padding-left: (12px - $border-width) !important;
    }
  }
  &.show-border {
    & .mat-form-field-flex {
      border-left-color: $bbraun-orange !important;
    }
  }
}

.undefined-border {
  border-width: 3px;
  border-left: 3px solid transparent !important;
  border-left-color: $bbraun-orange !important;

  & > * {
    margin-left: -3px;
  }
}

.view-menu {
  & .mat-menu-content {
    padding: 3px 0 !important;
  }
}

markdown {
  $link-color: #3d319c;
  a {
    font-weight: 500;
    color: $link-color;
    transition: all 150ms ease;

    &:hover {
      color: shade-color($link-color, 50%); //
    }
  }

  & .table-hover tr {
    transition: all 100ms ease;
  }
}

.add-containers-to-product-group-dialog {
  & .mat-form-field-subscript-wrapper {
    margin-top: 0.25rem;
  }

  & .mat-list-base[dense] {
    & .mat-list-option {
      height: 4rem;
      @media (min-width: 650px) {
        height: 2.5rem;
      }
      @include media-breakpoint-up(lg) {
        height: 2rem;
      }
    }
  }

  & .mat-dialog-actions .mat-button-base + .mat-button-base {
    margin: 0.5rem 0 1rem 0;
    @include media-breakpoint-up('md') {
      margin: 0;
    }
  }
}

.main-logo {
  vertical-align: baseline;
  height: 24px;
}

.custom-dialog-width {
  width: min(100%, 800px);
  max-width: unset !important;
}
