@use '@angular/material' as mat;
@import '@angular/material/theming';

// Definition of bbraun-color-maps
// Generated here: http://mcg.mbitson.com/#!?bbraunprimary=%2300b482&bbraunaccent=%23711e82&bbraunwarn=%23e57228&themename=bbraunlight
$bbraun-primary-color-map: (
  50: #e0f6f0,
  100: #b3e9da,
  200: #80dac1,
  300: #4dcba8,
  400: #26bf95,
  500: #00b482,
  600: #00ad7a,
  700: #00a46f,
  800: #009c65,
  900: #008c52,
  A100: #b8ffdd,
  A200: #85ffc5,
  A400: #52ffac,
  A700: #39ffa0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$bbraun-accent-color-map: (
  50: #eee4f0,
  100: #d4bcda,
  200: #b88fc1,
  300: #9c62a8,
  400: #864095,
  500: #711e82,
  600: #691a7a,
  700: #5e166f,
  800: #541265,
  900: #420a52,
  A100: #e187ff,
  A200: #d454ff,
  A400: #c721ff,
  A700: #c008ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$bbraun-warn-color-map: (
  50: #f8e0e9,
  100: #eeb3c9,
  200: #e280a5,
  300: #d64d81,
  400: #ce2666,
  500: #c5004b,
  600: #bf0044,
  700: #b8003b,
  800: #b00033,
  900: #a30023,
  A100: #ffced5,
  A200: #ff9ba9,
  A400: #ff687e,
  A700: #ff4e68,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// Define the resulting color palettes
$bbraun-primary-palette: mat.define-palette($bbraun-primary-color-map);
$bbraun-accent-palette: mat.define-palette($bbraun-accent-color-map);
$bbraun-warn-palette: mat.define-palette($bbraun-warn-color-map);

/* Define B. Braun color scheme */
$bbraun-light-grey: #f2f2f2;
$bbraun-grey: #6e6e6e;
$bbraun-dark-grey: #595959;
$bbraun-light-green: #baf4e6;
$bbraun-green: #00b482;
$bbraun-dark-green: #008f66;
$bbraun-purple-50: #eee4f0;
$bbraun-purple: #711e82;
$bbraun-dark-purple: #4f1d59;
$bbraun-light-orange: #f29000;
$bbraun-orange: rgb(229, 114, 40);
$bbraun-red: rgb(197, 0, 75);
$bbraun-red-80: rgb(209, 51, 111);
$bbraun-red-60: rgb(220, 102, 147);
$bbraun-red-40: rgb(232, 153, 183);
$bbraun-red-20: rgb(243, 204, 219);
$bbraun-dark-red: rgb(131, 7, 37);

/* Define B. Braun font scheme */
$bbraun-font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
$bbraun-font-weight-light: 300;
$bbraun-font-weight-normal: 400;
$bbraun-font-weight-bold: 700;

/* Font Color */
$bbraun-font-color: rgba(0, 0, 0, 0.87);

// Define color map
$bbraun-color: (
  primary: $bbraun-primary-palette,
  accent: $bbraun-accent-palette,
  warn: $bbraun-warn-palette
);
