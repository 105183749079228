// All definitions in this file are used to globally redefine styles for Angular Material components according to the B. Braun web
// applications style guide (aka "Toolguide"): https://www.bbraun-brand.com/bbraun-brand-guide/en/communication-in-use/digital/apps1.html
// All application local styles should go into "styles.scss".
@use '@angular/material' as mat;
// TODO: replace legacy-core with core once we replace legacy components with MDC-based components
@include mat.legacy-core();

@import 'bbraun-colors';

$bbraun-theme: mat.define-light-theme(
  (
    color: $bbraun-color,
    typography: mat.define-typography-config(),
    density: 0
  )
);
// TODO: replace `all-legacy-component-themes` with `all-component-themes` once we replace legacy components with MDC-based components
@include mat.all-legacy-component-themes($bbraun-theme);

// Base spacing unit for margin and padding. Use multiples of this value to define margins and paddings.
$bbraun-base-spacing: 4px;

// Include theme styles for core and each component
// @include angular-material-theme($bbraun-theme)

* {
  font-family: $bbraun-font-family;
}

// Redefinition of the mat-button styles:
// - buttons without a 'color' attribute are rendered as "Secondary button"
// - buttons with "color='primary'" are rendered as "Primary button"
// - buttons with "color='accent'" are rendered as "Call to action"
.mat-button {
  color: $bbraun-grey;
  font-weight: 700;
  font-size: 14px;
  background: #ffffff !important;
  border: 2px $bbraun-grey solid !important;

  &:hover {
    color: #ffffff !important;
    background: $bbraun-grey !important;
    text-decoration: none;
  }

  &.mat-primary {
    color: #ffffff !important;
    border: 2px $bbraun-green solid !important;
    background: $bbraun-green !important;

    &:hover {
      background: $bbraun-dark-green !important;
      border: 2px $bbraun-dark-green solid !important;
    }
  }
  &.mat-accent {
    color: #ffffff !important;
    border: 2px $bbraun-purple solid !important;
    background: $bbraun-purple !important;

    &:hover {
      background: $bbraun-dark-purple !important;
      border: 2px $bbraun-dark-purple solid !important;
    }
  }

  &.mat-warn {
    color: #ffffff !important;
    border: 2px $bbraun-red solid !important;
    background: $bbraun-red !important;

    &:hover {
      background: $bbraun-dark-red !important;
      border: 2px $bbraun-dark-red solid !important;
    }
  }
}

.mat-button:disabled {
  background: #c5c5c5 !important;
  color: #a2a2a2 !important;
  border: 2px solid #c5c5c5 !important;
  box-shadow: none !important;
  &:hover {
    background: #c5c5c5 !important;
    border: 2px solid #c5c5c5 !important;
    box-shadow: none !important;
  }
}

button.mat-flat-button {
  background: transparent !important;
  height: 3rem !important;
  color: $bbraun-grey;
}

.mat-fab {
  position: fixed;
  right: calc(7% + 65px);
  bottom: 85px;
  border-radius: 0 !important;
  background-color: $bbraun-grey !important;
  color: white !important;
  z-index: 1;
  @media screen and (max-width: 2000px) {
    right: 45px;
  }
  @media screen and (max-width: 1900px) {
    right: 50px;
  }
  @media screen and (max-width: 1700px) {
    right: 45px;
  }
  @media screen and (max-width: 1600px) {
    right: 35px;
  }
  @media screen and (max-width: 1024px) {
    right: 25px;
  }
}

.mat-table,
.mat-paginator {
  .material-icons {
    font-size: 20px;
  }

  .mat-icon-button {
    background: transparent !important;
    border-color: transparent !important;

    &:hover:not([disabled]) {
      border-color: transparent !important;
      color: $bbraun-dark-grey;

      .mat-icon {
        color: $bbraun-dark-grey !important;
      }
    }

    &.mat-accent {
      color: $bbraun-purple !important;

      &:hover {
        .mat-icon {
          color: $bbraun-dark-purple !important;
        }
      }
    }

    &.mat-warn {
      color: $bbraun-orange !important;

      &:hover {
        .mat-icon {
          color: shade-color($bbraun-orange, 10%) !important;
        }
      }
    }
  }
}

.mat-paginator {
  .mat-icon-button {
    background: transparent !important;
    border-color: transparent !important;

    &:hover:not([disabled]) {
      border-color: transparent !important;
      color: $bbraun-dark-grey !important;

      .mat-icon {
        color: $bbraun-dark-grey !important;
      }
    }
  }
}

.mat-button-focus-overlay {
  display: none;
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  button {
    &.mat-icon-button {
      border: none !important;
      height: unset !important;
      width: unset !important;
      font-size: inherit;

      &:hover:enabled {
        background: transparent !important;
        color: $bbraun-dark-grey !important;

        .mat-icon {
          color: $bbraun-dark-grey !important;
        }
      }
    }
  }
}

// Redefinition of mat-table and children
.mat-row:hover {
  background: $bbraun-light-grey;
  transition: background-color 0.2s ease;
}

.mat-cell {
  padding-right: 4px;
}

.mat-cell:last-of-type,
.mat-header-cell:last-of-type {
  padding-right: 24px;
}

.mat-cell:focus {
  outline: none;
}

// workaround for Angular Material bug: https://github.com/angular/material2/issues/9939
.mat-sort-header-sorted {
  .mat-sort-header-arrow {
    opacity: 1 !important;
    transform: translateY(0px) !important;
  }
}

.mat-sort-header-button {
  text-align: left;
}

.mat-tab-group {
  .mat-tab-body {
    padding: $bbraun-base-spacing * 4;
  }
}

.mat-expansion-panel {
  border-radius: 1px;
}

mat-checkbox {
  margin-bottom: 0.5rem;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $bbraun-green !important;
}

.mat-checkbox-checked.mat-optional .mat-checkbox-background {
  background-color: $bbraun-light-orange !important;
}

// center layout
.mat-snack-bar-container {
  display: flex !important;
  color: white;
}

// stop layout from resizing
.mat-snack-bar-handset {
  width: auto !important;
}

.warn {
  color: mat-color($bbraun-warn-palette, 500);
}

// Redefinition of the mat-chip styles:
.mat-chip {
  font-size: 14px;
  font-weight: 500;

  &.mat-success {
    color: #ffffff !important;
    border: 2px $bbraun-green solid !important;
    background: $bbraun-green !important;
  }

  &.mat-error {
    color: #ffffff !important;
    border: 2px $bbraun-red solid !important;
    background: $bbraun-red !important;

    &:hover {
      background: $bbraun-dark-red !important;
      border: 2px $bbraun-dark-red solid !important;
    }
  }

  &.mat-warn {
    color: #ffffff !important;
    border: 2px $bbraun-light-orange solid !important;
    background: $bbraun-light-orange !important;

    &:hover {
      background: $bbraun-orange !important;
      border: 2px $bbraun-orange solid !important;
    }
  }
}
